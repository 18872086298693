import React, { useState } from "react";
import PropTypes from "prop-types";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

const SearchableDropdown = ({ label = "", data, onSelect, value, dataTestId = "" }) => {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");

  return (
    <Autocomplete
      options={data}
      getOptionLabel={(option) => option.name} // Customize this based on your data structure
      value={data.find((option) => option._id === value) || null} // Match the selected value to the corresponding option
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      onChange={(event, selectedOption) => {
        // When an option is selected, call the onSelect prop with the selected option's ID
        onSelect(selectedOption ? selectedOption._id : null);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          data-testid={dataTestId}
        />
      )}
      renderOption={(props, option) => (
        <li {...props} key={option._id}>
          {option.name} {/* Customize display here */}
        </li>
      )}
      disableClearable
    />
  );
};

// Add propTypes for validation
SearchableDropdown.propTypes = {
  label: PropTypes.string, // Define the type of label prop
  data: PropTypes.array.isRequired, // Ensure data is required
  onSelect: PropTypes.func.isRequired, // Ensure onSelect is required
  value: PropTypes.string, // The value prop will be used to control the selected option
  dataTestId: PropTypes.string,
};

export default SearchableDropdown;
