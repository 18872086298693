import { useEffect, useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// @mui icons

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import Logo from "assets/images/logo.png";
import useToast from "hooks/useToast";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { postLogin } from "store/auth";

function Basic() {
  const { successToast, errorToast } = useToast();
  const dispatch = useDispatch();
  const { loggedInUser, loading, error } = useSelector((state) => state.auth);

  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("vlibrary@gmail.com");
  const [password, setPassword] = useState("12345678");
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();
  const handleLogin = () => {
    dispatch(postLogin({ email: email, password: password }));
  };

  const handleNavigation = debounce(() => {
    navigate("/dashboard");
  }, 300); // Adjust time as necessary

  useEffect(() => {
    if (loggedInUser) {
      successToast("Login successful");
      handleNavigation();
    }
    if (error) {
      errorToast(error);
    }
  }, [loggedInUser, error]);

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox borderRadius="lg" mx={2} mt={2} p={2} mb={1} textAlign="center">
          <MDBox component="img" src={Logo} alt="master card" width="60%" mt={1} />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                label="Email"
                value={email}
                fullWidth
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                label="Password"
                value={password}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton onClick={handleLogin} variant="gradient" color="info" fullWidth>
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
