export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const daySuffix = (n) => {
    if (n >= 11 && n <= 13) return "th";
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const year = date.getFullYear();
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  return `${month} ${day}${daySuffix(day)}, ${year} ${hours}:${minutes}${ampm}`;
};

// Function to find the last child in a hierarchy
export const getLastChildren = async (items) => {
  let lastChildren = [];

  for (const item of items) {
    if (item.child && item.child.length > 0) {
      const childResults = await getLastChildren(item.child);
      lastChildren.push(...childResults);
    } else {
      lastChildren.push({ _id: item._id, name: item.name });
    }
  }

  return lastChildren;
};

// Extract the last part of the path
export const getLastSegment = (pathname) => {
  const segments = pathname.split("/").filter(Boolean); // Split by '/' and remove empty strings
  return segments[segments.length - 1]; // Return the last segment
};
