// axios.js

import axios from "axios";

const apiBaseUrl = process.env.REACT_APP_API_URL;
// const apiBaseUrl = "https://vlibrary-827219904850.asia-east1.run.app/api/admin";

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: apiBaseUrl, // Set your base URL here
  timeout: 10000, // Request timeout
});

// Add a request interceptor (optional)
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify request config (e.g., add authorization headers)
    const token = localStorage.getItem("token"); // Example: get token from localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor (optional)
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle response error
    if (error.response && error.response.status === 401) {
      // For example: If the user is unauthorized
      console.error("Unauthorized! Logging out...");
      // Optionally, redirect to a login page or refresh token logic
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
