import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { getLastSegment } from "utils/helper";

export default function academicTableData(universitiesList, location) {
  const lastSegment = getLastSegment(location.pathname);

  let type = "";

  switch (lastSegment) {
    case "colleges":
      type = "College";
      break;
    case "schools":
      type = "School";
      break;
    case "universities":
      type = "University";
      break;
    case "institutes":
      type = "Institute";
      break;
  }
  const Job = ({ description = "" }) => (
    <MDBox lineHeight={1} textAlign="left">
      <Typography
        variant="caption"
        color={description == "Enable" ? "green" : description == "Disable" ? "red" : "black"}
        sx={{
          display: "inline-block",
          wordWrap: "break-word",
          overflowWrap: "break-word",
          maxWidth: "150px", // Ensure it respects the column width
        }}
      >
        {description}
      </Typography>
    </MDBox>
  );

  // Define prop types for Job component
  Job.propTypes = {
    description: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Name", accessor: "universityName", align: "left" },
      { Header: "Type", accessor: "universityType", align: "left" },
      { Header: "Address", accessor: "address", align: "left" },
      { Header: "Country", accessor: "country", align: "left" },
      { Header: "State", accessor: "state", align: "left" },
      { Header: "Pincode", accessor: "pincode", align: "left" },
      { Header: "Status", accessor: "isActive", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows:
      universitiesList?.map((item) => ({
        universityName: <Job description={item?.universityName} />,
        universityType: <Job description={item?.universityType} />,
        address: <Job description={item?.address} />,
        country: <Job description={item?.country} />,
        state: <Job description={item?.state} />,
        pincode: <Job description={item?.pincode} />,
        isActive: <Job description={item?.isActive ? "Enable" : "Disable"} />,
        action: (
          <Button
            href={`/academic/edit/${item._id}?type=${type}`}
            variant="contained"
            sx={{ px: 1.5, minWidth: "auto" }}
          >
            <EditIcon style={{ color: "white" }} />
          </Button>
        ),
      })) || [],
  };
}
