import axiosInstance from "api/axios";

const initialState = {
  topicsList: null,
  filteredTopicsList: null,
  topicDetail: null,
  loading: false,
  error: null,
};

const topicReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOPIC_LIST_REQUEST":
      return { ...state, loading: true };
    case "TOPIC_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        topicsList: action.payload,
        filteredTopicsList: action.payload.map((item) => ({
          id: item._id,
          name: item.topicName,
        })),
      };
    case "TOPIC_LIST_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "TOPIC_DETAIL_REQUEST":
      return { ...state, loading: true };
    case "TOPIC_DETAIL_SUCCESS":
      return { ...state, loading: false, topicDetail: action.payload };
    case "TOPIC_DETAIL_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "TOPIC_ADD_REQUEST":
      return { ...state, loading: true };
    case "TOPIC_ADD_SUCCESS":
      return { ...state, loading: false };
    case "TOPIC_ADD_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "TOPIC_UPDATE_REQUEST":
      return { ...state, loading: true };
    case "TOPIC_UPDATE_SUCCESS":
      return { ...state, loading: false };
    case "TOPIC_UPDATE_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

// To get list of all the topics
export const getTopics =
  (chapterId = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "TOPIC_LIST_REQUEST" });
      const response = await axiosInstance.get(`/topics/${chapterId ?? "all"}`);

      console.log(response.data.data, "topicsResponse");

      dispatch({ type: "TOPIC_LIST_SUCCESS", payload: response.data.data });
    } catch (error) {
      const errMessage = error?.response?.data?.message;
      dispatch({
        type: "TOPIC_LIST_FAIL",
        payload: errMessage,
      });
    }
  };

// To get the detail of a specific topic
export const getTopicDetail = (topicId) => async (dispatch) => {
  try {
    dispatch({ type: "TOPIC_DETAIL_REQUEST" });
    const response = await axiosInstance.get(`/topic/${topicId}`);

    console.log(response.data.data, "topicDetail");

    dispatch({ type: "TOPIC_DETAIL_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "TOPIC_DETAIL_FAIL",
      payload: errMessage,
    });
  }
};

// To add new topic
export const addNewTopic = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "TOPIC_ADD_REQUEST" });
    const response = await axiosInstance.post(`/topics`, formData);

    console.log(response.data.data, "addTopic");

    dispatch({ type: "TOPIC_ADD_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "TOPIC_ADD_FAIL",
      payload: errMessage,
    });
  }
};

// To update the specific topic detail
export const updateTopic = (topicId, formData) => async (dispatch) => {
  try {
    dispatch({ type: "TOPIC_UPDATE_REQUEST" });
    const response = await axiosInstance.put(`/topics/${topicId}`, formData);

    console.log(response.data.data, "updateTopic");

    dispatch({ type: "TOPIC_UPDATE_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "TOPIC_UPDATE_FAIL",
      payload: errMessage,
    });
  }
};

export default topicReducer;
