import axiosInstance from "api/axios";

const initialState = {
  userTypesList: null,
  userTypeDetail: null,
  loading: false,
  error: null,
  success: null,
};

const userTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USERTYPE_LIST_REQUEST":
      return { ...state, loading: true, success: null };
    case "USERTYPE_LIST_SUCCESS":
      return { ...state, loading: false, userTypesList: action.payload };
    case "USERTYPE_LIST_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "USERTYPE_DETAIL_REQUEST":
      return { ...state, loading: true };
    case "USERTYPE_DETAIL_SUCCESS":
      return { ...state, loading: false, userTypeDetail: action.payload };
    case "USERTYPE_DETAIL_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "USERTYPE_ADD_REQUEST":
      return { ...state, loading: true, success: null };
    case "USERTYPE_ADD_SUCCESS":
      return { ...state, loading: false, success: action.payload };
    case "USERTYPE_ADD_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "USERTYPE_UPDATE_REQUEST":
      return { ...state, loading: true, success: null };
    case "USERTYPE_UPDATE_SUCCESS":
      return { ...state, loading: false, success: action.payload };
    case "USERTYPE_UPDATE_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export const getUsertypes = (parentId) => async (dispatch) => {
  try {
    dispatch({ type: "USERTYPE_LIST_REQUEST" });
    const response = await axiosInstance.get(`/user_types/${parentId}`);
    // const data = await response.json();

    console.log(response.data.data, "userTypeResponse");

    dispatch({ type: "USERTYPE_LIST_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "USERTYPE_LIST_FAIL",
      payload: errMessage,
    });
  }
};

export const getUserTypeDetail = (parentId) => async (dispatch) => {
  try {
    dispatch({ type: "USERTYPE_DETAIL_REQUEST" });
    const response = await axiosInstance.get(`/user_type_detail/${parentId}`);
    // const data = await response.json();

    console.log(response.data.data, "userTypeDetailResponse");

    dispatch({ type: "USERTYPE_DETAIL_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "USERTYPE_DETAIL_FAIL",
      payload: errMessage,
    });
  }
};

// To add new usertype
export const addNewUserType = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "USERTYPE_ADD_REQUEST" });
    const response = await axiosInstance.post(`/user_types`, formData);

    console.log(response.data, "addUserType");

    dispatch({ type: "USERTYPE_ADD_SUCCESS", payload: response.data.message });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "USERTYPE_ADD_FAIL",
      payload: errMessage,
    });
  }
};

// To update the specific usertype detail
export const updateUserType = (userTypeId, formData) => async (dispatch) => {
  try {
    dispatch({ type: "USERTYPE_UPDATE_REQUEST" });
    const response = await axiosInstance.put(`/user_types/${userTypeId}`, formData);

    console.log(response.data, "updateUserType");

    dispatch({ type: "USERTYPE_UPDATE_SUCCESS", payload: response.data.message });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "USERTYPE_UPDATE_FAIL",
      payload: errMessage,
    });
  }
};

export default userTypeReducer;
