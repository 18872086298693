// useAuth.js

import { useSelector } from "react-redux";

const useAuth = () => {
  // Access the loggedInUser state from Redux store
  const loggedInUser = useSelector((state) => state.auth.loggedInUser);

  // Check if user is authenticated (you can modify this based on your logic)
  const isLoggedIn = Boolean(loggedInUser && loggedInUser?.data?.id); // Example: checking if user has an id

  return {
    isLoggedIn,
    user: loggedInUser, // You can return the user details as well if needed
  };
};

export default useAuth;
