import axiosInstance from "api/axios";

const initialState = {
  chaptersList: null,
  filteredChaptersList: null,
  chapterDetail: null,
  loading: false,
  error: null,
};

const chapterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHAPTER_LIST_REQUEST":
      return { ...state, loading: true };
    case "CHAPTER_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        chaptersList: action.payload,
        filteredChaptersList: action.payload.map((item) => ({
          id: item._id,
          name: item.chapterName,
        })),
      };
    case "CHAPTER_LIST_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "CHAPTER_DETAIL_REQUEST":
      return { ...state, loading: true };
    case "CHAPTER_DETAIL_SUCCESS":
      return { ...state, loading: false, chapterDetail: action.payload };
    case "CHAPTER_DETAIL_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "CHAPTER_ADD_REQUEST":
      return { ...state, loading: true };
    case "CHAPTER_ADD_SUCCESS":
      return { ...state, loading: false };
    case "CHAPTER_ADD_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "CHAPTER_UPDATE_REQUEST":
      return { ...state, loading: true };
    case "CHAPTER_UPDATE_SUCCESS":
      return { ...state, loading: false };
    case "CHAPTER_UPDATE_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

// To get list of all the chapters
export const getChapters =
  (subjectId = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "CHAPTER_LIST_REQUEST" });
      const response = await axiosInstance.get(`/chapters/${subjectId ?? "all"}`);

      console.log(response.data.data, "chaptersResponse");

      dispatch({ type: "CHAPTER_LIST_SUCCESS", payload: response.data.data });
    } catch (error) {
      const errMessage = error?.response?.data?.message;
      dispatch({
        type: "CHAPTER_LIST_FAIL",
        payload: errMessage,
      });
    }
  };

// To get the detail of a specific chapter
export const getChapterDetail = (chapterId) => async (dispatch) => {
  try {
    dispatch({ type: "CHAPTER_DETAIL_REQUEST" });
    const response = await axiosInstance.get(`/chapter/${chapterId}`);

    console.log(response.data.data, "chapterDetail");

    dispatch({ type: "CHAPTER_DETAIL_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "CHAPTER_DETAIL_FAIL",
      payload: errMessage,
    });
  }
};

// To add new chapter
export const addNewchapter = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "CHAPTER_ADD_REQUEST" });
    const response = await axiosInstance.post(`/chapters`, formData);

    console.log(response.data.data, "addChapter");

    dispatch({ type: "CHAPTER_ADD_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "CHAPTER_ADD_FAIL",
      payload: errMessage,
    });
  }
};

// To update the specific chapter detail
export const updateChapter = (chapterId, formData) => async (dispatch) => {
  try {
    dispatch({ type: "CHAPTER_UPDATE_REQUEST" });
    const response = await axiosInstance.put(`/chapters/${chapterId}`, formData);

    console.log(response.data.data, "updateChapter");

    dispatch({ type: "CHAPTER_UPDATE_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "CHAPTER_UPDATE_FAIL",
      payload: errMessage,
    });
  }
};

export default chapterReducer;
