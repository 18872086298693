import axiosInstance from "api/axios";

const initialState = {
  loggedInUser: null,
  loading: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_REQUEST":
      return { ...state, loading: true };
    case "LOGIN_SUCCESS":
      return { ...state, loading: false, loggedInUser: action.payload };
    case "LOGIN_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "LOGOUT_SUCCESS":
      return { ...state, loading: false, loggedInUser: null };
    default:
      return state;
  }
};

export const postLogin = (body) => async (dispatch) => {
  console.log(body, "PostLoginData");
  try {
    dispatch({ type: "LOGIN_REQUEST" });
    const data = await axiosInstance.post("/login", body);
    // const data = await response.json();

    console.log(data, "LoginResposne");

    dispatch({ type: "LOGIN_SUCCESS", payload: data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "LOGIN_FAIL",
      payload: errMessage,
    });
  }
};

export const postLogout = () => async (dispatch) => {
  dispatch({ type: "LOGOUT_SUCCESS" });
};

export default authReducer;
