import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getChapters } from "store/chapters";
import chapterTableData from "./columns";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";

function Chapters() {
  const dispatch = useDispatch();
  const { chaptersList, loading, error } = useSelector((state) => state.chapter);

  const { columns, rows } = chapterTableData(chaptersList); // Dynamically generate columns and rows

  useEffect(() => {
    dispatch(getChapters("all")); // Fetch chapters on component mount
  }, [dispatch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={9}>
                    <MDTypography variant="h6" color="white">
                      Chapters List
                    </MDTypography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Link to="/chapter/add" style={{ textDecoration: "none" }}>
                      <MDButton size="small">
                        <MDTypography variant="p">Add Chapter</MDTypography>
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Chapters;
