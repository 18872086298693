import { Box, Button, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { getUniversityDetail, updateUniversity } from "store/universities";
import useToast from "hooks/useToast";

function EditAcademic() {
  const { successToast, errorToast } = useToast();
  const { id } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { universityDetail, loading, error, success } = useSelector((state) => state.university);
  const [title, setTitle] = useState("");
  const [btnTitle, setBtnTitle] = useState("");

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset, // Added reset method
  } = useForm();

  const onSubmit = (data) => {
    console.log(data, "Form Submitted");
    dispatch(updateUniversity(id, data));
  };

  // Function to parse query parameters
  const getQueryParams = (search) => {
    const params = new URLSearchParams(search);
    return {
      type: params.get("type"), // Get the value of 'type'
    };
  };

  useEffect(() => {
    // Extract 'type' from query params
    const { type } = getQueryParams(location.search);

    if (type) {
      setTitle(type);
      switch (type) {
        case "College":
          setBtnTitle("colleges");
          break;
        case "School":
          setBtnTitle("schools");
          break;
        case "University":
          setBtnTitle("universities");
          break;
        case "Institute":
          setBtnTitle("institutes");
          break;
      }
    }
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getUniversityDetail(id));
    }
  }, [id, dispatch]);

  // Prefill form data when universityDetail is loaded
  useEffect(() => {
    if (universityDetail) {
      reset(universityDetail); // Reset the form with the fetched data
    }
  }, [universityDetail, reset]);

  useEffect(() => {
    if (error) {
      errorToast(error);
      // Display an error toast or log the error
      return;
    }
    if (success) {
      // Display a success toast or handle post-success logic
      successToast(success);
      return;
    }
  }, [error, success]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack p={3} gap={3}>
                  <Typography variant="h5">Edit {title}</Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="universityName"
                        control={control}
                        defaultValue="" // Initial value before the data is loaded
                        rules={{ required: "University/College Name is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="University/College Name"
                            variant="outlined"
                            fullWidth
                            error={!!errors.universityName}
                            helperText={errors.universityName?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="universityType"
                        control={control}
                        defaultValue=""
                        rules={{ required: "University Type is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="University Type"
                            fullWidth
                            sx={{ height: 42 }}
                            error={!!errors.universityType}
                          >
                            <MenuItem value="Central">Central</MenuItem>
                            <MenuItem value="Deemed to be Universities">
                              Deemed to be Universities
                            </MenuItem>
                            <MenuItem value="Private">Private</MenuItem>
                            <MenuItem value="State">State</MenuItem>
                          </Select>
                        )}
                      />
                      {errors.universityType && (
                        <Typography variant="caption" color="error">
                          {errors.universityType.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="address"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Address is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="University/College Address"
                            variant="outlined"
                            fullWidth
                            error={!!errors.address}
                            helperText={errors.address?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="state"
                        control={control}
                        defaultValue=""
                        rules={{ required: "State is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="State"
                            variant="outlined"
                            fullWidth
                            error={!!errors.state}
                            helperText={errors.state?.message}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="country"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Country is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Country"
                            variant="outlined"
                            fullWidth
                            error={!!errors.country}
                            helperText={errors.country?.message}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="pincode"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Pincode is required",
                          pattern: {
                            value: /^[0-9]{5,6}$/,
                            message: "Pincode must be a valid number with 5-6 digits",
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Pincode"
                            variant="outlined"
                            fullWidth
                            error={!!errors.pincode}
                            helperText={errors.pincode?.message}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="isActive"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Status is required" }}
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Status"
                            fullWidth
                            sx={{ height: 42 }}
                            error={!!errors.isActive}
                          >
                            <MenuItem value="true">Enable</MenuItem>
                            <MenuItem value="false">Disable</MenuItem>
                          </Select>
                        )}
                      />
                      {errors.isActive && (
                        <Typography variant="caption" color="error">
                          {errors.isActive.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Box>
                    <Button variant="outlined" size="small" sx={{ marginRight: 2 }}>
                      <Link to={`/${btnTitle}`}>
                        <Typography sx={{ color: "#000", fontSize: "16px", textTransform: "none" }}>
                          Back
                        </Typography>
                      </Link>
                    </Button>
                    <Button type="submit" variant="contained" size="small">
                      <Typography sx={{ color: "#fff", fontSize: "16px", textTransform: "none" }}>
                        Update & Continue
                      </Typography>
                    </Button>
                  </Box>
                </Stack>
              </form>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditAcademic;
