import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsertypes } from "store/userTypes";
import userTypesTableData from "layouts/usertypes/list/columns";
import { Link } from "react-router-dom";
import MDButton from "components/MDButton";

function UserTypes() {
  const dispatch = useDispatch();
  const { userTypesList, loading, error } = useSelector((state) => state.userType);

  const { columns, rows } = userTypesTableData(userTypesList); // Dynamically generate columns and rows

  useEffect(() => {
    dispatch(getUsertypes("all")); // Fetch user types on component mount
  }, [dispatch]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={9}>
                    <MDTypography variant="h6" color="white">
                      Usertypes List
                    </MDTypography>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UserTypes;
