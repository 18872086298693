import axiosInstance from "api/axios";

const initialState = {
  subjectsList: null,
  filteredSubjectsList: null,
  subjectDetail: null,
  loading: false,
  error: null,
};

const subjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SUBJECT_LIST_REQUEST":
      return { ...state, loading: true };
    case "SUBJECT_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        subjectsList: action.payload,
        filteredSubjectsList: action.payload.map((item) => ({
          _id: item._id,
          name: item.subjectName,
        })),
      };
    case "SUBJECT_LIST_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "SUBJECT_DETAIL_REQUEST":
      return { ...state, loading: true };
    case "SUBJECT_DETAIL_SUCCESS":
      return { ...state, loading: false, subjectDetail: action.payload };
    case "SUBJECT_DETAIL_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "SUBJECT_ADD_REQUEST":
      return { ...state, loading: true };
    case "SUBJECT_ADD_SUCCESS":
      return { ...state, loading: false };
    case "SUBJECT_ADD_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "SUBJECT_UPDATE_REQUEST":
      return { ...state, loading: true };
    case "SUBJECT_UPDATE_SUCCESS":
      return { ...state, loading: false };
    case "SUBJECT_UPDATE_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

// To get list of all the subjects
export const getSubjects =
  (courseId = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "SUBJECT_LIST_REQUEST" });
      const response = await axiosInstance.get(`/subjects/${courseId ?? "all"}`);

      console.log(response.data.data, "subjectsResponse");

      dispatch({ type: "SUBJECT_LIST_SUCCESS", payload: response.data.data });
    } catch (error) {
      const errMessage = error?.response?.data?.message;
      dispatch({
        type: "SUBJECT_LIST_FAIL",
        payload: errMessage,
      });
    }
  };

// To get the detail of a specific subject
export const getSubjectDetail = (subjectId) => async (dispatch) => {
  try {
    dispatch({ type: "SUBJECT_DETAIL_REQUEST" });
    const response = await axiosInstance.get(`/subject/${subjectId}`);

    console.log(response.data.data, "subjectDetail");

    dispatch({ type: "SUBJECT_DETAIL_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "SUBJECT_DETAIL_FAIL",
      payload: errMessage,
    });
  }
};

// To add new subject
export const addNewSubject = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "SUBJECT_ADD_REQUEST" });
    const response = await axiosInstance.post(`/subjects`, formData);

    console.log(response.data.data, "addSubject");

    dispatch({ type: "SUBJECT_ADD_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "SUBJECT_ADD_FAIL",
      payload: errMessage,
    });
  }
};

// To update the specific subject detail
export const updateSubject = (subjectId, formData) => async (dispatch) => {
  try {
    dispatch({ type: "SUBJECT_UPDATE_REQUEST" });
    const response = await axiosInstance.put(`/subjects/${subjectId}`, formData);

    console.log(response.data.data, "updateSubject");

    dispatch({ type: "SUBJECT_UPDATE_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "SUBJECT_UPDATE_FAIL",
      payload: errMessage,
    });
  }
};

export default subjectReducer;
