import axiosInstance from "api/axios";

const initialState = {
  coursesList: null,
  filteredCoursesList: null,
  courseDetail: null,
  loading: false,
  error: null,
};

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "COURSE_LIST_REQUEST":
      return { ...state, loading: true, success: null };
    case "COURSE_LIST_SUCCESS":
      return {
        ...state,
        loading: false,
        coursesList: action.payload,
        filteredCoursesList: action.payload.map((item) => ({
          _id: item._id,
          name: item.courseName,
        })),
      };
    case "COURSE_LIST_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "COURSE_DETAIL_REQUEST":
      return { ...state, loading: true, success: null };
    case "COURSE_DETAIL_SUCCESS":
      return { ...state, loading: false, courseDetail: action.payload };
    case "COURSE_DETAIL_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "COURSE_ADD_REQUEST":
      return { ...state, loading: true, success: null };
    case "COURSE_ADD_SUCCESS":
      return { ...state, loading: false, success: action.payload };
    case "COURSE_ADD_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "COURSE_UPDATE_REQUEST":
      return { ...state, loading: true, success: null };
    case "COURSE_UPDATE_SUCCESS":
      return { ...state, loading: false, success: action.payload };
    case "COURSE_UPDATE_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

// To get list of all the courses
export const getCourses =
  (userTypeId = "") =>
  async (dispatch) => {
    try {
      dispatch({ type: "COURSE_LIST_REQUEST" });
      const response = await axiosInstance.get(`/courses/${userTypeId ?? "all"}`);

      console.log(response.data.data, "coursesResponse");

      dispatch({ type: "COURSE_LIST_SUCCESS", payload: response.data.data });
    } catch (error) {
      const errMessage = error?.response?.data?.message;
      dispatch({
        type: "COURSE_LIST_FAIL",
        payload: errMessage,
      });
    }
  };

// To get the detail of a specific course
export const getCourseDetail = (courseId) => async (dispatch) => {
  try {
    dispatch({ type: "COURSE_DETAIL_REQUEST" });
    const response = await axiosInstance.get(`/course/${courseId}`);

    console.log(response.data.data, "courseDetail");

    dispatch({ type: "COURSE_DETAIL_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "COURSE_DETAIL_FAIL",
      payload: errMessage,
    });
  }
};

// To add new course
export const addNewCourse = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "COURSE_ADD_REQUEST" });
    const response = await axiosInstance.post(`/courses`, formData);

    console.log(response.data.message, "addCourse");

    dispatch({ type: "COURSE_ADD_SUCCESS", payload: response.data.message });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "COURSE_ADD_FAIL",
      payload: errMessage,
    });
  }
};

// To update the specific course detail
export const updateCourse = (courseId, formData) => async (dispatch) => {
  try {
    dispatch({ type: "COURSE_UPDATE_REQUEST" });
    const response = await axiosInstance.put(`/courses/${courseId}`, formData);

    console.log(response.data.message, "updateCourse");

    dispatch({ type: "COURSE_UPDATE_SUCCESS", payload: response.data.message });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "COURSE_UPDATE_FAIL",
      payload: errMessage,
    });
  }
};

export default courseReducer;
