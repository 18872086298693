import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUniversities } from "store/universities";
import academicTableData from "./columns";
import { Link, useLocation } from "react-router-dom";
import MDButton from "components/MDButton";
import { getLastSegment } from "utils/helper";

function AcademicList() {
  const location = useLocation();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [btnTitle, setBtnTitle] = useState("");
  const { universitiesList, loading, error } = useSelector((state) => state.university);

  const { columns, rows } = academicTableData(universitiesList, location); // Dynamically generate columns and rows

  useEffect(() => {
    // Get the last segment of the current URL path
    const lastSegment = getLastSegment(location.pathname);
    setTitle(lastSegment);
    switch (lastSegment) {
      case "colleges":
        setBtnTitle("College");
        break;
      case "schools":
        setBtnTitle("School");
        break;
      case "universities":
        setBtnTitle("University");
        break;
      case "institutes":
        setBtnTitle("Institute");
        break;
    }
  }, [location]);

  useEffect(() => {
    if (title != "") dispatch(getUniversities(title)); // Fetch user types on component mount
  }, [title]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading data</p>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} lg={9}>
                    <MDTypography variant="h6" color="white" sx={{ textTransform: "capitalize" }}>
                      {title} List
                    </MDTypography>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={3}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Link to={`/academic/add?type=${btnTitle}`} style={{ textDecoration: "none" }}>
                      <MDButton size="small">
                        <MDTypography variant="p">Add {btnTitle}</MDTypography>
                      </MDButton>
                    </Link>
                  </Grid>
                </Grid>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AcademicList;
