import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Card, Grid, Stack, TextField, Typography } from "@mui/material";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useToast from "hooks/useToast";
import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getCourses } from "store/courses";
import { addNewCourse } from "store/courses";
import { addNewSubject } from "store/subjects";
import { getUsertypes } from "store/userTypes";

function AddSubject() {
  const dispatch = useDispatch();
  const { successToast, errorToast } = useToast();
  const { userTypesList } = useSelector((state) => state.userType);
  const { filteredCoursesList } = useSelector((state) => state.course);
  const { error, success } = useSelector((state) => state.subject);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [typesList, setTypesList] = useState([]);
  const [parentId, setParentId] = useState("");

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      userType: "",
      courseId: "",
      subjectName: "",
      subSubjectNames: [{ name: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "subSubjectNames",
  });

  const handleSelectParent = (id, level) => {
    if (level == -1) {
      setValue("courseId", id);
      return;
    }
    const newSelectedTypes = [...selectedTypes];
    newSelectedTypes[level] = id;

    if (level === 0) setValue("userType", id);

    setSelectedTypes(newSelectedTypes.slice(0, level + 1));
    setParentId(id);
  };

  const onSubmit = (data) => {
    console.log("Form Data Submitted:", data, selectedTypes);
    const dropdownCount = document.querySelectorAll("[data-testid='dynamic-dropdown']").length;

    console.log(dropdownCount, "dropdownCount");

    if (dropdownCount !== selectedTypes.length) {
      return errorToast("First select all the usertypes");
    }

    const formData = {
      courseId: data.courseId,
      subjectName: data.subjectName,
      subSubjectNames: data.subSubjectNames,
    };

    setSelectedTypes([]);

    // Handle form submission logic here
    // dispatch(addNewSubject(formData));
  };

  useEffect(() => {
    dispatch(getUsertypes("all"));
  }, [dispatch]);

  useEffect(() => {
    if (userTypesList && userTypesList.length > 0) {
      const options = [...typesList];
      options[selectedTypes.length] = userTypesList;
      setTypesList(options.slice(0, selectedTypes.length + 1));
    }
  }, [userTypesList]);

  useEffect(() => {
    // Trigger a re-fetch for each level change
    if (parentId) {
      dispatch(getUsertypes(parentId));
      dispatch(getCourses(parentId));
    }
  }, [parentId]);

  useEffect(() => {
    if (error) {
      errorToast(error);
      return;
    }
    if (success) {
      console.log(success, "Success");
      successToast(success);
      setValue("userType", "");
      setValue("subjectName", "");
      setValue("subSubjectNames", [{ name: "" }]);
      return;
    }
  }, [error, success]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={6} pt={2} pb={3}>
        <Grid item xs={12}>
          <Card>
            <Stack p={3} gap={3} component="form" onSubmit={handleSubmit(onSubmit)}>
              <Typography variant="h5">Add New Subject</Typography>
              <Stack gap={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SearchableDropdown
                      label="Select Usertype"
                      data={typesList[0] ?? []}
                      onSelect={(id) => handleSelectParent(id, 0)}
                      value={selectedTypes[0] || ""}
                      dataTestId="dynamic-dropdown"
                      {...register("userType", { required: "Usertype is required" })}
                    />
                    {errors.userType && (
                      <Typography color="error" sx={{ fontSize: "12px", paddingLeft: 2 }}>
                        {errors.userType.message}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                {selectedTypes.map((type, index) => {
                  const options = typesList[index + 1];
                  if (options?.length > 0)
                    return (
                      <Grid container spacing={2} key={`sub-user-type-${index}`}>
                        <Grid item xs={12} sm={6}>
                          <SearchableDropdown
                            label={`Select Sub Usertype ${index + 1}`}
                            data={options}
                            onSelect={(id) => handleSelectParent(id, index + 1)}
                            value={selectedTypes[index + 1] || ""}
                            dataTestId="dynamic-dropdown"
                          />
                        </Grid>
                      </Grid>
                    );
                  return null;
                })}

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <SearchableDropdown
                      label="Select Course"
                      data={filteredCoursesList ?? []}
                      onSelect={(id) => handleSelectParent(id, -1)}
                      dataTestId="dynamic-dropdown"
                      {...register("courseId", { required: "Usertype is required" })}
                    />
                    {errors.userType && (
                      <Typography color="error" sx={{ fontSize: "12px", paddingLeft: 2 }}>
                        {errors.courseId.message}
                      </Typography>
                    )}
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Enter Subject Name"
                      variant="outlined"
                      fullWidth
                      {...register("subjectName", { required: "Subject name is required" })}
                      error={!!errors.subjectName}
                      helperText={errors.subjectName?.message}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button variant="outlined" size="small" onClick={() => append({ name: "" })}>
                      <Typography sx={{ color: "#000", fontSize: "16px", textTransform: "none" }}>
                        Add Sub-Subject
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>

                {fields.map((field, index) => (
                  <Grid container spacing={2} key={field.id}>
                    <Grid item xs={1} sm={1}></Grid>
                    <Grid item xs={9} sm={4}>
                      <TextField
                        label={`Sub-Subject ${index + 1}`}
                        variant="outlined"
                        fullWidth
                        {...register(`subSubjectNames.${index}.name`, {
                          required: "Sub-subject name is required",
                        })}
                        error={!!errors.subSubjectNames?.[index]?.name}
                        helperText={errors.subSubjectNames?.[index]?.name?.message}
                      />
                    </Grid>
                    <Grid item xs={2} sm={1}>
                      <Box
                        sx={{
                          borderColor: "#ccc",
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderRadius: 1.5,
                          display: "inline-flex",
                          padding: 0.65,
                          cursor: "pointer",
                        }}
                        onClick={() => remove(index)}
                      >
                        <DeleteIcon sx={{ fontSize: "28px", color: "#aaa" }} />
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Stack>

              <Box>
                <Button variant="outlined" size="small" sx={{ marginRight: 2 }}>
                  <Link to="/subjects">
                    <Typography sx={{ color: "#000", fontSize: "16px", textTransform: "none" }}>
                      Back
                    </Typography>
                  </Link>
                </Button>
                <Button type="submit" variant="contained" size="small">
                  <Typography sx={{ color: "#fff", fontSize: "16px", textTransform: "none" }}>
                    Save & Continue
                  </Typography>
                </Button>
              </Box>
            </Stack>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default AddSubject;
