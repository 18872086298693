import axiosInstance from "api/axios";

const initialState = {
  universitiesList: null,
  universityDetail: null,
  loading: false,
  error: null,
  success: null,
};

const universityReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UNIVERSITY_LIST_REQUEST":
      return { ...state, loading: true, success: null };
    case "UNIVERSITY_LIST_SUCCESS":
      return { ...state, loading: false, universitiesList: action.payload };
    case "UNIVERSITY_LIST_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "UNIVERSITY_DETAIL_REQUEST":
      return { ...state, loading: true, success: null };
    case "UNIVERSITY_DETAIL_SUCCESS":
      return { ...state, loading: false, universityDetail: action.payload };
    case "UNIVERSITY_DETAIL_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "UNIVERSITY_ADD_REQUEST":
      return { ...state, loading: true, success: null };
    case "UNIVERSITY_ADD_SUCCESS":
      return { ...state, loading: false, success: action.payload };
    case "UNIVERSITY_ADD_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "UNIVERSITY_UPDATE_REQUEST":
      return { ...state, loading: true, success: null };
    case "UNIVERSITY_UPDATE_SUCCESS":
      return { ...state, loading: false, success: action.payload };
    case "UNIVERSITY_UPDATE_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

// To get list of all the universities
export const getUniversities = (type) => async (dispatch) => {
  try {
    dispatch({ type: "UNIVERSITY_LIST_REQUEST" });
    const response = await axiosInstance.get(`/universities?type=${type}`);

    console.log(response.data.data, "universitiesResponse");

    dispatch({ type: "UNIVERSITY_LIST_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "UNIVERSITY_LIST_FAIL",
      payload: errMessage,
    });
  }
};

// To get the detail of a specific university
export const getUniversityDetail = (universityId) => async (dispatch) => {
  try {
    dispatch({ type: "UNIVERSITY_DETAIL_REQUEST" });
    const response = await axiosInstance.get(`/university/${universityId}`);

    console.log(response.data.data, "universityDetail");

    dispatch({ type: "UNIVERSITY_DETAIL_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "UNIVERSITY_DETAIL_FAIL",
      payload: errMessage,
    });
  }
};

// To add new university
export const addNewUniversity = (formData) => async (dispatch) => {
  try {
    dispatch({ type: "UNIVERSITY_ADD_REQUEST" });
    const response = await axiosInstance.post(`/universities`, formData);

    console.log(response.data, "addUniversity");

    dispatch({ type: "UNIVERSITY_ADD_SUCCESS", payload: response.data.message });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "UNIVERSITY_ADD_FAIL",
      payload: errMessage,
    });
  }
};

// To update the specific university detail
export const updateUniversity = (universityId, formData) => async (dispatch) => {
  try {
    dispatch({ type: "UNIVERSITY_UPDATE_REQUEST" });
    const response = await axiosInstance.put(`/universities/${universityId}`, formData);

    console.log(response.data, "updateUniversity");

    dispatch({ type: "UNIVERSITY_UPDATE_SUCCESS", payload: response.data.message });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "UNIVERSITY_UPDATE_FAIL",
      payload: errMessage,
    });
  }
};

export default universityReducer;
