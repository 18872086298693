import { toast } from "react-toastify";

const useToast = () => {
  const successToast = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000, // Close after 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { fontSize: "14px" },
    });
  };

  const errorToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: { fontSize: "14px" },
    });
  };

  return { successToast, errorToast };
};

export default useToast;
