import axiosInstance from "api/axios";

const initialState = {
  usersList: null,
  userDetail: null,
  loading: false,
  error: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_LIST_REQUEST":
      return { ...state, loading: true };
    case "USER_LIST_SUCCESS":
      return { ...state, loading: false, usersList: action.payload };
    case "USER_LIST_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "USER_DETAIL_REQUEST":
      return { ...state, loading: true };
    case "USER_DETAIL_SUCCESS":
      return { ...state, loading: false, userDetail: action.payload };
    case "USER_DETAIL_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

// To get list of all the registered users
export const getUsers = () => async (dispatch) => {
  try {
    dispatch({ type: "USER_LIST_REQUEST" });
    const response = await axiosInstance.get("/users");

    console.log(response.data.data, "usersResponse");

    dispatch({ type: "USER_LIST_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "USER_LIST_FAIL",
      payload: errMessage,
    });
  }
};

// To get the detail of a specific user
export const getUserDetail = (id) => async (dispatch) => {
  try {
    dispatch({ type: "USER_DETAIL_REQUEST" });
    const response = await axiosInstance.get(`/profile/${id}`);
    // const data = await response.json();

    console.log(response.data.data, "userDetailResponse");

    dispatch({ type: "USER_DETAIL_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "USER_DETAIL_FAIL",
      payload: errMessage,
    });
  }
};

export default userReducer;
