import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addNewUserType } from "store/userTypes";
import useToast from "hooks/useToast";

function AddUsertype() {
  const { successToast, errorToast } = useToast();
  const dispatch = useDispatch();
  const { loading, error, success } = useSelector((state) => state.userType);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data, "Form Submitted");

    data.parentType = "";
    dispatch(addNewUserType(data));
  };

  useEffect(() => {
    if (error) {
      errorToast(error);
      return;
    }
    if (success) {
      successToast(success);
      return;
    }
  }, [error, success]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack p={3} gap={3}>
                  <Typography variant="h5">Add New User type</Typography>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Usertype Name is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Usertype Name"
                            variant="outlined"
                            fullWidth
                            error={!!errors.name}
                            helperText={errors.name?.message}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>

                  <Box>
                    <Button variant="outlined" size="small" sx={{ marginRight: 2 }}>
                      <Link to="/usertypes">
                        <Typography sx={{ color: "#000", fontSize: "16px", textTransform: "none" }}>
                          Back
                        </Typography>
                      </Link>
                    </Button>
                    <Button type="submit" variant="contained" size="small">
                      <Typography sx={{ color: "#fff", fontSize: "16px", textTransform: "none" }}>
                        Save & Continue
                      </Typography>
                    </Button>
                  </Box>
                </Stack>
              </form>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddUsertype;
