import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";

export default function userTableData(usersList) {
  const Job = ({ description = "" }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  // Define prop types for Job component
  Job.propTypes = {
    description: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Name", accessor: "name", width: "28%", align: "left" },
      { Header: "Email", accessor: "email", width: "28%", align: "left" },
      { Header: "Mobile", accessor: "mobile", width: "28%", align: "left" },
      { Header: "KYC ?", accessor: "isKycDone", width: "28%", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows:
      usersList?.map((item) => ({
        name: <Job description={item?.name} />,
        email: <Job description={item?.email} />,
        mobile: <Job description={item?.mobile} />,
        isKycDone: <Job description={item?.isKycDone ? "Yes" : "No"} />,
        action: (
          <Link to="#">
            {/* <Link to={`/user/view/${item._id}`}> */}
            <Button variant="contained" sx={{ px: 1.5, minWidth: "auto" }}>
              <VisibilityIcon style={{ color: "white" }} />
            </Button>
          </Link>
        ),
      })) || [],
  };
}
