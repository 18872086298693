import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "store/users";
import userTableData from "./columns";
import { Typography } from "@mui/material";

function User() {
  const dispatch = useDispatch();
  const { usersList, loading, error } = useSelector((state) => state.user);

  const { columns, rows } = userTableData(usersList); // Dynamically generate columns and rows

  useEffect(() => {
    dispatch(getUsers()); // Fetch user types on component mount
  }, [dispatch]);

  if (error) return <p>Error loading data</p>;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={4} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Users List
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {loading ? (
                  <Typography variant="h5" textAlign="center" pb={3}>
                    Loading ...
                  </Typography>
                ) : (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default User;
