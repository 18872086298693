// ProtectedRoute.js

import React from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types"; // Import PropTypes
import useAuth from "hooks/useAuth";

const ProtectedRoute = ({ component }) => {
  const { isLoggedIn } = useAuth(); // Check if the user is authenticated

  if (!isLoggedIn) {
    return <Navigate to="/sign-in" />; // Redirect to sign-in if not logged in
  }

  return component; // If logged in, render the component
};

// Define PropTypes for the component prop
ProtectedRoute.propTypes = {
  component: PropTypes.element.isRequired,
};

export default ProtectedRoute;
