import { applyMiddleware, combineReducers, createStore } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Default to localStorage for web
import { thunk } from "redux-thunk"; // Fix typo in the import
import activityReducer from "./activities";
import authReducer from "./auth"; // Your reducer
import chapterReducer from "./chapters";
import courseReducer from "./courses";
import subjectReducer from "./subjects";
import topicReducer from "./topics";
import universityReducer from "./universities";
import userTypeReducer from "./userTypes";
import userReducer from "./users";
// Persist Config for 'auth' reducer
const persistConfig = {
  key: "root", // Key for storage
  storage, // Storage to use (localStorage by default)
  whitelist: ["auth"], // List of reducers you want to persist
};

// Combine your reducers
const rootReducer = combineReducers({
  auth: authReducer,
  userType: userTypeReducer,
  user: userReducer,
  university: universityReducer,
  course: courseReducer,
  subject: subjectReducer,
  chapter: chapterReducer,
  topic: topicReducer,
  activity: activityReducer,
});

// Wrap the root reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with the persisted reducer
const store = createStore(
  persistedReducer,
  applyMiddleware(thunk) // Use dev tools in development
);

// Create a persistor for handling the persisting of the store
export const persistor = persistStore(store);

export default store;
