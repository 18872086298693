import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getUserTypeDetail } from "store/userTypes";
import { getLastChildren } from "utils/helper";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm, useFieldArray } from "react-hook-form";
import { addNewCourse, getCourseDetail } from "store/courses";
import routes from "routes";

function EditCourse() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { courseDetail, loading, error, success } = useSelector((state) => state.course);
  const [typesList, setTypesList] = useState([]);

  // Initialize react-hook-form
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      courseName: "", // For capturing course name
      subCourseNames: [{ name: "" }], // Default sub-courses state
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "subCourseNames",
  });

  // Update handleSelect to set the userType ID in the form
  const handleSelect = (id) => {
    console.log("Selected ID:", id);
    setValue("userTypeId", id); // Set the selected user type ID in the form
  };

  useEffect(() => {
    // Dispatch actions to fetch user types and course details
    dispatch(getUserTypeDetail("66f97a80c5c0fc7fc8b20474"));
    dispatch(getCourseDetail(id));
  }, [id]);

  useEffect(() => {
    if (error) {
      // Handle error
      return;
    }
    if (success) {
      alert(success);
      return;
    }
  }, [error, success]);

  // Use this effect to populate the form with course details
  useEffect(() => {
    if (courseDetail) {
      // Set values based on fetched courseDetail

      setValue("courseName", courseDetail.courseDetail.courseName);
      setValue(
        "subCourseNames",
        courseDetail.subCourses.map((subCourse) => ({
          name: subCourse.courseName,
          id: subCourse._id,
        }))
      );
    }
  }, [courseDetail, setValue]);

  const onSubmit = (data) => {
    console.log("Form Data Submitted:", data);
    // dispatch(addNewCourse(data)); // Uncomment when ready to use
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Stack p={3} gap={3} component="form" onSubmit={handleSubmit(onSubmit)}>
                <Typography variant="h5">Edit Course Detail</Typography>
                <Stack gap={3}>
                  <Grid container spacing={2}>
                    {/* Course Name Input */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Enter Course Name"
                        variant="outlined"
                        fullWidth
                        {...register("courseName", { required: "Course name is required" })}
                        error={!!errors.courseName}
                        helperText={errors.courseName?.message}
                        InputLabelProps={{
                          shrink: true, // This prop ensures the label shrinks properly
                        }}
                        margin="normal" // Adds some margin for better spacing
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Button
                        variant="outlined"
                        size="small"
                        onClick={() => append({ name: "" })}
                        sx={{ marginTop: "18px" }}
                      >
                        <Typography sx={{ color: "#000", fontSize: "16px", textTransform: "none" }}>
                          Add Sub-Course
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>

                  {fields.map((field, index) => (
                    <Grid container spacing={2} key={field.id}>
                      <Grid item xs={1} sm={1}></Grid>
                      <Grid item xs={9} sm={4}>
                        <TextField
                          label={`Sub-Course ${index + 1}`}
                          variant="outlined"
                          fullWidth
                          {...register(`subCourseNames.${index}.name`, {
                            required: "Sub-course name is required",
                          })}
                          error={!!errors.subCourseNames?.[index]?.name}
                          helperText={errors.subCourseNames?.[index]?.name?.message}
                        />
                      </Grid>
                      <Grid item xs={2} sm={1}>
                        <Box
                          sx={{
                            borderColor: "#ccc",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderRadius: 1.5,
                            display: "inline-flex",
                            padding: 0.65,
                            cursor: "pointer",
                          }}
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon sx={{ fontSize: "28px", color: "#aaa" }} />
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Stack>
                <Box>
                  <Button variant="outlined" size="small" sx={{ marginRight: 2 }}>
                    <Link to="/courses">
                      <Typography sx={{ color: "#000", fontSize: "16px", textTransform: "none" }}>
                        Back
                      </Typography>
                    </Link>
                  </Button>
                  <Button type="submit" variant="contained" size="small">
                    <Typography sx={{ color: "#fff", fontSize: "16px", textTransform: "none" }}>
                      Update & Continue
                    </Typography>
                  </Button>
                </Box>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EditCourse;
