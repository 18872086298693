import { Stack, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getActivities } from "store/activities";
import { getUserDetail } from "store/users";
import { formatDate } from "utils/helper";
import PropTypes from "prop-types";

import { Box, Tabs, Tab } from "@mui/material";

function TabPanel({ children, value, index }) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// Define prop types for TabPanel
TabPanel.propTypes = {
  children: PropTypes.element,
  value: PropTypes.number,
  index: PropTypes.number,
};

function ViewUser() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const { userDetail, loading, error } = useSelector((state) => state.user);
  const { activitiesList } = useSelector((state) => state.activity);

  useEffect(() => {
    dispatch(getUserDetail(id));
  }, [id]);

  useEffect(() => {
    dispatch(getActivities());
  }, []);

  useEffect(() => {
    console.log(userDetail, "userDetailUI");
  }, [userDetail]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ borderRight: 1, borderColor: "divider", minWidth: "150px" }}>
                  <Tabs
                    orientation="vertical"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{ borderRight: 1, borderColor: "divider", padding: 2 }}
                  >
                    <Tab label="Dashboard" />
                    <Tab label="Basic Details" />
                    <Tab label="Usertypes" />
                    <Tab label="Academic Details" />
                    <Tab label="Address" />
                    <Tab label="Transactions" />
                  </Tabs>
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <TabPanel value={value} index={0}>
                    This is the Home tab content.
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    This is the Profile tab content.
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    This is the Messages tab content.
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    This is the Settings tab content.
                  </TabPanel>
                </Box>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewUser;
