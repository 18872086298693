import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function courseTableData(coursesList) {
  const Job = ({ description = "" }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  // Define prop types for Job component
  Job.propTypes = {
    description: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Course Name", accessor: "courseName", width: "45%", align: "left" },
      { Header: "UserType", accessor: "name", width: "45%", align: "left" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows:
      coursesList?.map((item) => ({
        courseName: <Job description={item?.courseName} />,
        name: <Job description={item?.userTypeId?.name} />,
        action: (
          <Link to="#">
            <Button variant="contained" sx={{ px: 1.5, minWidth: "auto" }}>
              <EditIcon style={{ color: "white" }} />
            </Button>
          </Link>
        ),
      })) || [],
  };
}
