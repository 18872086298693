import axiosInstance from "api/axios";

const initialState = {
  activitiesList: null,
  activityDetail: null,
  loading: false,
  error: null,
};

const activityReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ACTIVITY_LIST_REQUEST":
      return { ...state, loading: true };
    case "ACTIVITY_LIST_SUCCESS":
      return { ...state, loading: false, activitiesList: action.payload };
    case "ACTIVITY_LIST_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "ACTIVITY_DETAIL_REQUEST":
      return { ...state, loading: true };
    case "ACTIVITY_DETAIL_SUCCESS":
      return { ...state, loading: false, activityDetail: action.payload };
    case "ACTIVITY_DETAIL_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

// To get list of all the registered users
export const getActivities = () => async (dispatch) => {
  try {
    dispatch({ type: "ACTIVITY_LIST_REQUEST" });
    const response = await axiosInstance.get("/activities");

    console.log(response.data.data, "activitiesResponse");

    dispatch({ type: "ACTIVITY_LIST_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "ACTIVITY_LIST_FAIL",
      payload: errMessage,
    });
  }
};

// To get the detail of a specific user
export const getActivityDetail = () => async (dispatch) => {
  try {
    dispatch({ type: "ACTIVITY_LIST_REQUEST" });
    const response = await axiosInstance.get("/users");
    // const data = await response.json();

    console.log(response.data.data, "userTypeResponse");

    dispatch({ type: "ACTIVITY_LIST_SUCCESS", payload: response.data.data });
  } catch (error) {
    const errMessage = error?.response?.data?.message;
    dispatch({
      type: "ACTIVITY_LIST_FAIL",
      payload: errMessage,
    });
  }
};

export default activityReducer;
