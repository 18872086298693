import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button } from "@mui/material";
import PropTypes from "prop-types";

export default function activityTableData(universitiesList) {
  const Job = ({ description = "" }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  // Define prop types for Job component
  Job.propTypes = {
    description: PropTypes.string.isRequired,
  };

  return {
    columns: [
      { Header: "Name", accessor: "activityName", width: "34%", align: "left" },
      { Header: "Total", accessor: "total", width: "34%", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows:
      universitiesList?.map((item) => ({
        activityName: <Job description={item?.activityName} />,
        total: <Job description={0} />,

        action: (
          <Button
            href={`/activity/${item._id}`}
            variant="contained"
            sx={{ px: 1.5, minWidth: "auto" }}
          >
            <VisibilityIcon style={{ color: "white" }} />
          </Button>
        ),
      })) || [],
  };
}
