import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import useToast from "hooks/useToast";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addNewUserType, getUserTypeDetail, updateUserType } from "store/userTypes";

// PropTypes for nested items
const itemPropType = PropTypes.shape({
  _id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  child: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      child: PropTypes.array,
    })
  ),
});

// Recursive component to render nested structure
const NestedList = ({
  items,
  userTypeName,
  handleUserTypeName,
  handleAddUserType,
  handleUpdateUserType,
  openInputBoxId,
  setOpenInputBoxId,
  openEditBoxId,
  setOpenEditBoxId,
}) => {
  return (
    <>
      {items.length > 0 &&
        items.map((item) => (
          <NestedItem
            key={item._id}
            item={item}
            userTypeName={userTypeName}
            handleUserTypeName={handleUserTypeName}
            handleAddUserType={handleAddUserType}
            handleUpdateUserType={handleUpdateUserType}
            openInputBoxId={openInputBoxId}
            setOpenInputBoxId={setOpenInputBoxId}
            openEditBoxId={openEditBoxId}
            setOpenEditBoxId={setOpenEditBoxId}
          />
        ))}
    </>
  );
};

// Define prop types for NestedList
NestedList.propTypes = {
  items: PropTypes.arrayOf(itemPropType).isRequired,
  userTypeName: PropTypes.string,
  handleUserTypeName: PropTypes.func.isRequired, // Define as function
  handleAddUserType: PropTypes.func.isRequired, // Define as function
  handleUpdateUserType: PropTypes.func.isRequired, // Define as function
  openInputBoxId: PropTypes.string,
  setOpenInputBoxId: PropTypes.func,
  openEditBoxId: PropTypes.string,
  setOpenEditBoxId: PropTypes.func,
};

// Component to render each item with lines indicating hierarchy
const NestedItem = ({
  item,
  level = 0,
  userTypeName,
  handleUserTypeName,
  handleAddUserType,
  handleUpdateUserType,
  openInputBoxId,
  setOpenInputBoxId,
  openEditBoxId,
  setOpenEditBoxId,
}) => {
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleShowInput = () => {
    setOpenInputBoxId(openInputBoxId === item._id ? null : item._id);
  };
  const handleShowEditInput = () => {
    console.log(openEditBoxId, item._id);
    setOpenEditBoxId(openEditBoxId === item._id ? null : item._id);
    setOpenEdit((prev) => !prev);
    handleUserTypeName(item.name);
  };

  return (
    <Box sx={{ position: "relative", paddingLeft: "20px", paddingBottom: "10px" }}>
      {/* Draw vertical line for parent item */}
      {level > 0 && (
        <Box
          sx={{
            position: "absolute",
            left: "-10px",
            top: "0",
            bottom: "0",
            width: "2px",
            backgroundColor: "#000",
            marginLeft: "9px",
          }}
        />
      )}

      <Stack direction="row" alignItems="center" gap="10px" mb={3}>
        <Box
          sx={{
            borderColor: "#ccc",
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 1,
            display: "inline-flex",
            padding: 0.5,
            cursor: "pointer",
          }}
          onClick={handleShowInput}
        >
          <Typography component="span" sx={{ fontSize: "14px", width: "15px", height: "15px" }}>
            <AddIcon />
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
            paddingLeft: "5px",
          }}
        >
          {openEditBoxId != item._id && (
            <>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              <Typography variant="h6" onClick={handleToggle}>
                {item.name}
              </Typography>

              <Typography
                component="span"
                sx={{ fontSize: "14px", width: "15px", height: "15px", marginLeft: 2 }}
                onClick={handleShowEditInput}
              >
                <EditIcon titleAccess="Click to edit name" />
              </Typography>
            </>
          )}

          {openEditBoxId == item._id && (
            <Stack direction="row" mb={3}>
              <TextField
                label="Enter Usertype Name"
                sx={{ marginLeft: "28px", width: "250px", mr: 2 }}
                onChange={(e) => handleUserTypeName(e.target.value)}
                value={userTypeName}
              />
              <Button
                variant="contained"
                size="small"
                onClick={() => handleUpdateUserType(item._id)}
              >
                <Typography sx={{ color: "#fff", fontSize: "12px", textTransform: "none" }}>
                  Update
                </Typography>
              </Button>
              <Typography
                component="span"
                sx={{
                  fontSize: "24px",

                  marginLeft: 2,
                  borderColor: "#ccc",
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderRadius: 1,
                  display: "inline-flex",
                  padding: 1,
                  cursor: "pointer",
                }}
                onClick={handleShowEditInput}
                title="Click to close edit box"
              >
                <CloseIcon />
              </Typography>
            </Stack>
          )}
        </Box>
      </Stack>

      {/* Conditionally render the input box only if this item is active */}
      {openInputBoxId === item._id && (
        <Stack direction="row" mb={3}>
          <TextField
            label="Enter Usertype Name"
            sx={{ marginLeft: "28px", width: "250px", mr: 2 }}
            onChange={(e) => handleUserTypeName(e.target.value)}
            value={userTypeName}
          />
          <Button variant="contained" size="small" onClick={() => handleAddUserType(item._id)}>
            <Typography sx={{ color: "#fff", fontSize: "16px", textTransform: "none" }}>
              Add
            </Typography>
          </Button>
        </Stack>
      )}

      {open && item.child && item.child.length > 0 && (
        <Box sx={{ paddingLeft: "10px" }}>
          <NestedList
            items={item.child}
            userTypeName={userTypeName}
            handleUserTypeName={handleUserTypeName}
            handleAddUserType={handleAddUserType}
            handleUpdateUserType={handleUpdateUserType}
            openInputBoxId={openInputBoxId}
            setOpenInputBoxId={setOpenInputBoxId}
            openEditBoxId={openEditBoxId}
            setOpenEditBoxId={setOpenEditBoxId}
          />
        </Box>
      )}
    </Box>
  );
};
// Define prop types for NestedItem
NestedItem.propTypes = {
  item: itemPropType.isRequired,
  level: PropTypes.number,
  userTypeName: PropTypes.string,
  handleUserTypeName: PropTypes.func.isRequired, // Define as function
  handleAddUserType: PropTypes.func.isRequired, // Define as function
  handleUpdateUserType: PropTypes.func.isRequired, // Define as function
  openInputBoxId: PropTypes.string,
  setOpenInputBoxId: PropTypes.func,
  openEditBoxId: PropTypes.string,
  setOpenEditBoxId: PropTypes.func,
};

function ViewUserType() {
  const { successToast, errorToast } = useToast();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [openInputBoxId, setOpenInputBoxId] = useState(null);
  const [openEditBoxId, setOpenEditBoxId] = useState(null);

  const [userTypeName, setUserTypeName] = useState("");

  const { userTypeDetail, loading, error, success } = useSelector((state) => state.userType);

  const handleAddUserType = (pId) => {
    const data = {
      name: userTypeName,
      parentType: pId,
    };

    console.log(data, "UsertypeData");

    dispatch(addNewUserType(data));
  };

  const handleUpdateUserType = (pId) => {
    const data = {
      name: userTypeName,
    };

    console.log(data, "UsertypeData");

    dispatch(updateUserType(pId, data));
  };

  const getDetail = () => {
    dispatch(getUserTypeDetail(id));
  };

  useEffect(() => {
    if (error) {
      errorToast(error);
      return;
    }
    if (success) {
      successToast(success);
      setUserTypeName("");
      setOpenEditBoxId(null);
      // setOpenInputBoxId(null);
      dispatch(getUserTypeDetail(id));
      return;
    }
  }, [error, success]);

  const handleUserTypeName = (name) => {
    setUserTypeName(name);
  };

  useEffect(() => {
    getDetail();
  }, [id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <Stack p={3} gap={3}>
                <Typography variant="h5">User Type</Typography>

                <NestedList
                  items={userTypeDetail ?? []}
                  handleUserTypeName={handleUserTypeName}
                  handleAddUserType={handleAddUserType}
                  handleUpdateUserType={handleUpdateUserType}
                  userTypeName={userTypeName}
                  openInputBoxId={openInputBoxId}
                  setOpenInputBoxId={setOpenInputBoxId}
                  openEditBoxId={openEditBoxId}
                  setOpenEditBoxId={setOpenEditBoxId}
                />
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ViewUserType;
