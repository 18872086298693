import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getChapters } from "store/chapters";
import { getCourses } from "store/courses";
import { getSubjects } from "store/subjects";
import { addNewUniversity } from "store/universities";

function AddTopic() {
  const dispatch = useDispatch();
  // const { successToast, errorToast } = useToast();
  const { loading, error, success } = useSelector((state) => state.university);

  const [courseId, setCourseId] = useState("");
  const [subjectId, setSubjectId] = useState("");
  const [chapterId, setChapterId] = useState("");

  const { filteredCoursesList } = useSelector((state) => state.course);
  const { filteredSubjectsList } = useSelector((state) => state.subject);
  const { filteredChaptersList } = useSelector((state) => state.chapter);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data, "Form Submitted");
    dispatch(addNewUniversity(data));
  };

  useEffect(() => {
    if (error) {
      // errorToast("error");
      return;
    }
    if (success) {
      // successToast("success");
      alert(success);
      return;
    }
  }, [error, success]);

  useEffect(() => {
    dispatch(getCourses("all"));
    dispatch(getSubjects("all"));
    dispatch(getChapters("all"));
  }, []);

  console.log(filteredCoursesList, "filteredCoursesList");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={2} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack p={3} gap={3}>
                  <Typography variant="h5">Add New Topic</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="isActive"
                        control={control}
                        defaultValue="true"
                        rules={{ required: "Status is required" }}
                        render={({ field }) => (
                          <SearchableDropdown label="Select Course" data={filteredCoursesList} />
                        )}
                      />
                      {errors.isActive && (
                        <Typography variant="caption" color="error">
                          {errors.isActive.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="isActive"
                        control={control}
                        defaultValue="true"
                        rules={{ required: "Status is required" }}
                        render={({ field }) => (
                          <SearchableDropdown label="Select Subject" data={filteredSubjectsList} />
                        )}
                      />
                      {errors.isActive && (
                        <Typography variant="caption" color="error">
                          {errors.isActive.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="isActive"
                        control={control}
                        defaultValue="true"
                        rules={{ required: "Status is required" }}
                        render={({ field }) => (
                          <SearchableDropdown label="Select Chapter" data={filteredChaptersList} />
                        )}
                      />
                      {errors.isActive && (
                        <Typography variant="caption" color="error">
                          {errors.isActive.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Controller
                        name="topicName"
                        control={control}
                        defaultValue="" // Initial value before the data is loaded
                        rules={{ required: "Topic Name is required" }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="Enter Topic Name"
                            variant="outlined"
                            fullWidth
                            error={!!errors.universityName}
                            helperText={errors.universityName?.message}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                  <Box>
                    <Button variant="outlined" size="small" sx={{ marginRight: 2 }}>
                      <Link to="/topics">
                        <Typography sx={{ color: "#000", fontSize: "16px", textTransform: "none" }}>
                          Back
                        </Typography>
                      </Link>
                    </Button>
                    <Button type="submit" variant="contained" size="small">
                      <Typography sx={{ color: "#fff", fontSize: "16px", textTransform: "none" }}>
                        Save & Continue
                      </Typography>
                    </Button>
                  </Box>
                </Stack>
              </form>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AddTopic;
